@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../src/css/table.css";
@import "../src/css/form.css";
@import "../src/css/main-layout.css";
@import "../src/css/ui-component.css";

@layer components {
  .overlay {
    @apply before:content-[''] before:top-0 before:block before:w-full before:h-full before:left-0 before:fixed before:bg-overlay2;
  }
  .absolute-center-align {
    @apply mx-auto absolute left-0 right-0 top-1/2 translate-y-50;
  }
  .flex-center-align {
    @apply flex justify-center items-center;
  }
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("./assets//fonts/Poppins.ttf");
}
@font-face {
  font-family: "Figtree";
  src: url("./assets//fonts/Figtree.ttf");
}
@font-face {
  font-family: "Inter-bold";
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Figtree-bold";
  src: url("./assets//fonts/Figtree-Bold.ttf");
}
@font-face {
  font-family: "Figtree-medium";
  src: url("./assets/fonts/Figtree-Medium.ttf");
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 50px;
  height: 50px;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background: #529dfe0e;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2a57b6;
}
.icon-filter:hover {
  filter: invert(67%) sepia(98%) saturate(4804%) hue-rotate(175deg)
    brightness(104%) contrast(104%);
  background: transparent !important;
}
.dark-blue-filter:hover img {
  filter: invert(46%) sepia(50%) saturate(3834%) hue-rotate(225deg)
    brightness(63%) contrast(121%);
}

.white-filter:hover img {
  filter: invert(99%) sepia(86%) saturate(2%) hue-rotate(195deg)
    brightness(119%) contrast(100%);
}
.blue-filter:hover img {
  filter: invert(66%) sepia(63%) saturate(6577%) hue-rotate(229deg)
    brightness(54%) contrast(116%);
}
.green-filter:hover img {
  filter: brightness(0) saturate(100%) invert(36%) sepia(73%) saturate(2076%)
    hue-rotate(138deg) brightness(94%) contrast(102%);
}
.red-filter:hover img {
  filter: brightness(0) saturate(100%) invert(38%) sepia(75%) saturate(1244%)
    hue-rotate(338deg) brightness(102%) contrast(106%);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
