.form-container {
  @apply bg-white p-5 mt-3 2xs:max-lg:p-4 2xs:max-md:pt-0 2xs:max-sm:mb-3 h-[calc(100vh-5em)] 2xs:max-lg:h-[calc(100vh-4em)] 2xs:max-md:h-[calc(100vh-5em)] overflow-auto;
}
.form-border {
  @apply border rounded-lg p-6 border-lg5 mt-4;
}

.leftCol {
  @apply w-6/12 pr-2 2xs:max-sm:w-full 2xs:max-sm:pr-0;
}
.rightCol {
  @apply w-6/12 pl-2 2xs:max-sm:w-full 2xs:max-sm:pl-0 2xs:max-sm:mb-3;
}
.submitWrap {
  @apply flex justify-center pt-5;
}
.addWrap {
  @apply pr-2 2xs:max-sm:pr-0;
}
.grayWrap {
  @apply pl-2 h-full 2xs:max-sm:pl-1;
}
.readyOnlyLabel {
  @apply font-poppins text-dg2 text-sm;
}
.readyOnlyInput {
  @apply py-3 px-3 border-bw rounded-md text-sm font-figtree focus-visible:outline-dark-blue border-lg4 mt-1 mb-2 text-lg7;
}
.currentLoan {
  @apply font-figtree text-dg2 text-sm pb-2;
}
.addLoanBtnWrap {
  @apply flex justify-center py-9 mt-5 2xs:max-sm:py-5 2xs:max-sm:mt-2;
}
.selectedImgContainer {
  @apply border-2 border-dashed mt-5 relative;
}
.selectedImgScroll {
  @apply overflow-y-auto h-130 2xs:max-sm:h-115;
}
.removeSelectedImg {
  @apply w-3 absolute right-1 top-1 cursor-pointer rounded-full shadow-sh-1 border-1;
}
.selectedImgLen {
  @apply absolute right-2 top-0 bg-white text-sm text-dark-blue font-bold p-2;
}
.selectedImg {
  @apply w-full p-2 2xs:max-sm:w-20 2xs:max-sm:h-20 cursor-pointer object-cover h-full;
}
.inputLeftCol {
  @apply w-6/12 pr-1 2xs:max-md:w-full 2xs:max-md:pr-0;
}
.inputRightCol {
  @apply w-6/12 pl-1 2xs:max-md:w-full 2xs:max-md:pl-0;
}
.complaintBtnWrap {
  @apply flex justify-center lg:mt-4 py-9;
}
.userInfoWrap {
  @apply border-lg5 border-1 mt-3 max-base:p-3 rounded-t-lg;
}
.userInfoHeading {
  @apply text-white font-figtree text-lg bg-dark-blue py-1 px-4 rounded-t-lg;
}
.halfCol {
  @apply w-2/4 2xs:max-sm:w-full;
}
.userFormErr {
  @apply text-center bg-red-200 text-red-1 font-figtreeMedium max-w-sm mt-6 mx-auto rounded;
}
.addBtn---wrap {
  @apply flex justify-center pt-8 mt-6 2xs:max-sm:mt-0;
}

/* user role checkboxes dropdown */
.checkBoxDropdownLabel {
  @apply text-dg2 text-sm font-poppins;
}
.checkBoxDropdownLabelWrap {
  @apply w-full border-bw cursor-pointer rounded-md py-3 px-3 text-sm;
}
.checksDropdownArr {
  @apply font-poppins absolute right-2 top-3 cursor-pointer font-semibold;
}
.checksOptionWrap {
  @apply bg-white w-full rounded absolute border-1 border-lg2 mx-1 py-2 left-0 z-99 shadow-sh-1 transition-all ease-in-out duration-300 px-3;
}
.dropdown-li {
  @apply px-1 cursor-pointer hover:bg-lb3 text-dg2 font-figtree border-b-1 border-b-lg5 py-1;
}
.dropdown-li-search {
  @apply w-full border-bw cursor-pointer rounded-md py-3 px-3 font-figtree text-sm placeholder:text-sm placeholder:font-figtree placeholder:text-black focus:outline-none focus:ring-1 focus:ring-blue-300;
}
.checksItems {
  @apply flex w-full capitalize dropdown-li;
}
.checksLabel {
  @apply cursor-pointer pl-1 flex w-full;
}
/* user role checkboxes dropdown end */
.accent-input {
  @apply cursor-pointer accent-dark-blue;
}
.input-err {
  @apply text-red-500 font-figtree text-xs h-4;
}
.machinesErr {
  @apply text-sm text-amber-300  font-figtreeMedium pb-2;
}
.confirmErr {
  @apply bg-red-200 text-red-1 font-figtreeMedium mt-6 mb-2 mx-auto rounded max-w-md flex px-2 justify-center;
}

/* upload sheet form */
.uploaded--sheet-name {
  @apply text-xs font-poppins bg-lg5 px-2 rounded-full py-1 text-dark-blue 2xs:max-sm:py-0 inline-flex items-center;
}
.remove--uploaded--sheet {
  @apply w-4 cursor-pointer 2xs:max-sm:w-3;
}
.sheet-upload-btn-wrap {
  @apply flex justify-center py-9;
}
/* upload sheet form END */

/* change password */
.change--pass-border--wrap {
  @apply max-w-3xl mx-auto border-2 border-lg5 p-9 pb-12 rounded-md 2xs:max-sm:px-2 w-full;
}
.change--pass-btn {
  @apply bg-button-bg text-white py-2 h-47 px-4 rounded-md font-poppins border-2 border-white hover:border-dark-blue hover:bg-none hover:text-dark-blue transition duration-75 ease-linear;
}
.successChangePassWrap {
  @apply text-center bg-white max-w-lg w-11/12 py-16 rounded-2xl z-9999;
}
.successPassHeading {
  @apply text-green-3 font-figtreeBold text-lg pt-2;
}
/* change password  END */

/* LOGIN FORM  */
.login-main {
  @apply sm:w-4/6 sm:max-md:w-4/5 flex items-center justify-center;
}
.login-content {
  @apply w-full py-4 2xs:max-sm:px-4;
}
.login-container {
  @apply max-w-2xl mx-auto sm:pr-4 sm:pl-28 sm:max-lg:pr-12 2xs:max-sm:mt-20;
}
.login-logo {
  @apply w-36 lg:w-48 2xs:max-sm:mx-auto 2xs:max-sm:w-32;
}
.login-heading {
  @apply text-black text-2xl 2xs:max-sm:text-base font-bold pb-5 md:pb-8 font-interBold pt-8 2xs:max-sm:text-center 2xs:max-sm:pt-3 2xs:max-sm:pb-4;
}
.loginErr {
  @apply font-figtree text-red-error flex;
}
.loginBtn {
  @apply bg-dark-blue w-full text-white p-3 rounded-md font-poppins border-2 border-dark-blue hover:bg-transparent hover:text-dark-blue transition duration-75 ease-linear;
}
.disableBtn {
  @apply cursor-not-allowed pointer-events-none bg-black;
}
.forgotPassText {
  @apply underline text-dark-blue font-medium text-sm cursor-pointer font-interBold;
}
.rem-forgotPass--wrap {
  @apply flex pt-4 justify-between pb-6;
}
.login-header {
  @apply sm:w-1/3 sm:max-md:w-1/5 flex items-center md:h-auto justify-center bg-dark-blue relative 2xs:max-sm:h-28;
}
.login-header--img {
  @apply absolute object-contain top-1/2 w-500 right-184n translate-y-50 sm:max-lg:w-300 sm:max-lg:right-72n 2xs:max-sm:w-44 2xs:max-sm:right-0 2xs:max-sm:left-0 2xs:max-sm:mx-auto 2xs:max-sm:mt-10;
}
/* LOGIN FORM  END*/

/* Cylinder Multi checks Dropdown */

.cy-search-inp {
  @apply focus-visible:border-dark-blue w-full text-dark-blue focus-visible:outline-0 border-1 border-dark-blue rounded h-9 pl-2 pr-5 placeholder:text-dark-blue placeholder:text-sm placeholder:font-poppins;
}
.cy-dropdown-li {
  @apply px-1 cursor-pointer flex w-full items-baseline hover:bg-lb3 text-dg2 font-figtree capitalize border-b-1 border-b-lg5 py-1;
}
.cy-dropdown-size {
  @apply bg-slate-400 text-white text-xs px-1 rounded-md h-4 ml-1 mt-1;
}
.cy-dropdown-search-err {
  @apply font-figtree text-red-400 text-xs pl-4;
}
.cy-load-txt {
  @apply text-center text-dark-blue pt-4 text-lg;
}
.cy-receive-form {
  @apply form-border max-w-4xl mx-auto w-95% form-border;
}
.cy-dispatch-addVehicle {
  @apply text-dark-blue text-right font-figtreeMedium flex justify-end mt-2;
}
.cy-add-more-dispatch {
  @apply border p-3 pt-7 rounded mb-2 relative bg-white;
}
.dispatch-remove-cy {
  @apply absolute right-2 top-2 w-5 cursor-pointer;
}
.cy-add-more-button {
  @apply text-sec-blue text-right font-figtreeMedium flex justify-end w-full mt-2;
}
.cy-addMoreTxt {
  @apply border-b-1 border-sec-blue;
}
.cy-updateBtn {
  @apply bg-button-bg text-white py-[6px] px-4 rounded-md font-poppins border-2 border-white hover:border-dark-blue hover:bg-none hover:text-dark-blue transition duration-75 ease-linear;
}
/* Cylinder Multi checks Dropdown  END*/
