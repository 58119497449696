.page-layout--container {
  @apply w-[calc(100%-251px)] md:max-lg:w-[calc(100%-200px)] 2xs:max-md:w-full h-full;
}
/* HEADER  */
.header-row {
  @apply h-16 bg-dark-blue flex justify-between items-center 2xs:max-md:justify-between px-3 shadow-sh-1 md:max-lg:h-12 2xs:max-md:h-14 2xs:max-sm:px-3 relative;
}
.backBtn {
  @apply flex items-center 2xs:max-md:hidden;
}
.pageTitleHeading {
  @apply text-lg text-white font-figtree 2xs:max-sm:text-lg pl-2;
}
.profileDropdown-wrap {
  @apply flex items-center cursor-pointer;
}
.usernameText {
  @apply text-white font-figtree px-1 2xs:max-lg:text-xs 2xs:max-sm:hidden;
}
.profile-dropdown-arr {
  @apply 2xs:max-sm:ml-2 group-hover:rotate-180n mt-1;
}
.show-dropdown-wrap {
  @apply grid bg-white absolute right-4 w-48 2xs:max-sm:w-40 top-16 opacity-0 invisible transition-all ease-in-out duration-500 shadow-sh-2 z-9999;
}
.dropdown-link {
  @apply flex items-center 2xs:max-sm:text-sm 2xs:max-sm:px-3 px-5;
}
/* HEADER END  */

/* SIDE BAR */
.sidebar-main-1 {
  @apply w-sidebar md:max-lg:w-200;
}
.sidebar-main-2 {
  @apply before:fixed before:left-0 before:w-full before:h-full before:bg-overlay before:z-99 before:content-[''];
}
.sidebar-wrap {
  @apply 2xs:max-md:w-sidebar shadow-sh-1 bg-white 2xs:max-md:absolute 2xs:max-md:z-9999 transition-all duration-500;
}
.sidebar-logo-wrap {
  @apply px-4 h-16 py-4 md:max-lg:h-12 flex justify-between items-center;
}
.sidebar-logo {
  @apply w-28 mt-2px md:max-lg:w-24 2xs:max-lg:mt-0;
}
.menu-wrap {
  @apply overflow-y-auto h-[calc(100vh-4rem)] md:max-lg:h-[calc(100vh-3rem)] bg-dark-blue pt-3;
}
.main-menu-hover {
  @apply hover:bg-[#9ca3af66] transition-all duration-500 ease-in-out; 
}
.main-menu {
  @apply px-4 py-3 flex relative font-poppins text-base md:max-lg:text-sm capitalize text-white main-menu-hover;
}
.menu-ico {
  @apply w-5 h-5 md:max-lg:w-4 md:max-lg:h-4;
}
.menu-arr {
  @apply font-serif absolute right-4 top-22;
}
.subMenu--ul {
  @apply bg-white overflow-hidden transition-all duration-500;
}
.subMenu-link {
  @apply pl-10 py-1 flex items-center hover:bg-lb3 md:max-lg:text-sm;
}
/* SIDE BAR END */

/* PAGE NOT FOUND */
.page-not-found-wrap {
  @apply flex items-center justify-center h-full;
}
.page-not-found-heading {
  @apply text-2xl text-dark-blue text-center font-figtreeBold pt-4;
}
/* PAGE NOT FOUND END */

/* BREAD CUM */
.breadCum-wrap {
  @apply flex justify-end h-16 items-center 2xs:max-lg:h-14 relative;
}
.breadCum-txt {
  @apply absolute left-0 font-figtreeBold text-dark-blue;
}
.breadCum-link {
  @apply text-sm font-figtree text-lg3 px-1 flex;
}
.breadCum-mainMenu {
  @apply text-sm capitalize px-1 flex font-semibold text-dark-blue;
}
/* BREAD CUM END*/

/* HOME */
.home-wrapper {
  @apply flex sm:flex-wrap 2xs:max-sm:flex-col flex-row-reverse h-[calc(100vh-9em)] 2xs:max-lg:h-[calc(100vh-7em)] 2xs:max-md:h-[calc(100vh-7em)] overflow-auto;
}
.home-profile-main {
  @apply sm:w-2/5 sm:pl-3 sm:max-lg:pl-1 2xs:max-sm:mb-3;
}
.home-profile-wrap {
  @apply bg-white pb-6 h-full shadow-sh-2;
}
.home-profile-top {
  @apply h-40 flex justify-items-end items-center bg-lb4 relative sm:max-lg:h-28 2xs:max-sm:h-20;
}
.home-profile-pic {
  @apply w-20 h-20 absolute left-8 bottom-27 rounded-full sm:max-lg:w-11 sm:max-lg:h-11 sm:max-lg:bottom-22n 2xs:max-lg:left-4;
}
.home-profile-vector {
  @apply ml-auto sm:max-lg:w-36 2xs:max-sm:w-32;
}
.homeProfileDetailsWrap {
  @apply pt-9 pl-10 pr-5 2xs:max-lg:pl-5;
}
.home-profile-h {
  @apply text-light-blue-2 font-poppins pb-2;
}
.homeIdProof {
  @apply absolute right-0 top-5n;
}
.viewProfileBtn {
  @apply text-center w-56 m-auto mt-8 2xs:max-lg:mt-5;
}
.homeModule-main {
  @apply sm:pr-3 sm:max-lg:pr-1 sm:w-3/5 2xs:max-sm:mb-0;
}
.homeModule-wrap {
  @apply bg-white text-center p-5 h-full 2xs:max-lg:p-2;
}
.homeModule-h {
  @apply text-2xl text-dark-blue font-poppins font-bold py-2 sm:max-lg:text-xl 2xs:max-sm:text-lg;
}
.homeModule-link {
  @apply w-2/6 2xs:max-base:w-2/4;
}
.homeModule-wrap {
  @apply p-4 2xs:max-lg:p-2 2xs:max-base:p-1;
}
.homeModule-wrap-img {
  @apply py-45px px-2 2xs:max-lg:p-6;
}
.homeModule-img {
  @apply w-16 h-16 m-auto 2xs:max-lg:w-12 2xs:max-lg:h-12;
}
.homeModule-title {
  @apply bg-lb4 text-white py-3 2xs:max-sm:py-2 px-1 rounded-b-md flex justify-center font-figtree capitalize text-lg sm:max-lg:text-sm 2xs:max-sm:text-xs;
}
/* HOME END */

/* PROFILE */
.profile-container {
  @apply h-[calc(100vh-em)] 2xs:max-lg:h-[calc(100vh-7em)] 2xs:max-md:h-[calc(100vh-9em)] overflow-auto;
}
.profileWrap {
  @apply pb-6 h-full shadow-sh-2;
}
.profileHeaderContainer {
  @apply flex justify-items-end items-center bg-lb4 relative;
}
.profileImg {
  @apply w-20 h-20 absolute left-5 bottom-27 rounded-full 2xs:max-lg:w-11 2xs:max-lg:h-11 2xs:max-lg:bottom-22n 2xs:max-lg:left-4 border-3 border-white;
}
.profileVector {
  @apply ml-auto sm:max-lg:w-36 2xs:max-sm:w-32 w-36;
}
.email-role-wrap {
  @apply pl-6 pt-8 2xs:max-lg:pl-4;
}
.profile-email-text {
  @apply font-figtreeMedium text-dark-blue pb-2;
}
.profile-role {
  @apply bg-lb4 text-white text-center text-sm rounded-full font-figtree capitalize px-3 py-1 mb-1 mr-1;
}
.profile-tabs-main {
  @apply flex pt-4 pl-5 bg-white rounded-b-md 2xs:max-lg:pl-4;
}
.profileTabActive {
  @apply border-b-dark-blue text-dark-blue;
}
.profileTab {
  @apply border-transparent text-dg4;
}
.profileTabContentWrap {
  @apply bg-white mt-1 pl-5 py-3 2xs:max-lg:pl-4;
}
/* PROFILE END */

/* REMARK */
.main-remark-container {
  @apply rounded-md border border-lg5 shadow-md mt-4 relative;
}
.remark-msg {
  @apply font-figtreeMedium text-center border-1 border-lg5 mx-4 mb-2 py-3 rounded-md flex items-center justify-center;
}
.add-remark-btn {
  @apply bg-dark-blue text-white text-xs py-1 border-dark-blue flex items-center px-2 h-full rounded-md transition-all ease-in-out duration-300 font-figtree border-1 hover:bg-white hover:border-dark-blue hover:text-dark-blue;
}
.remark-form-wrap {
  @apply px-4 pb-4 border-lg5 border-1 mx-4 mb-4 rounded-md;
}
.remark-form-btn-wrap {
  @apply flex justify-end 2xs:max-sm:justify-center pt-2;
}
.remark-table {
  @apply w-full table-auto text-center border-collapse border border-lg5 flex flex-col;
}
.remark-thead {
  @apply text-white font-poppins text-sm font-extralight bg-dark-blue 2xs:max-sm:text-sm table table-fixed;
}
.remark-tbody {
  @apply font-figtree 2xs:max-sm:text-xs overflow-y-auto;
}
.remark-tr {
  @apply w-full overflow-y-auto table table-fixed text-sm;
}
.remarkTd {
  @apply border border-lg5;
}
